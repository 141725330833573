<template>
  <div>
    <div class="mb-8 flex" style="align-items: center; justify-content: space-between">
      <h2>Actualización masiva</h2>
      <vs-button @click="$router.push({name: 'products'})">
        Regresar
      </vs-button>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <vx-card>
      <vs-table stripe pagination :max-items="50" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              {{ tr.ID }}
            </vs-td>
            <vs-td>
              {{ tr.NAME }}
            </vs-td>
            <vs-td>
              {{ tr.SKU }}
            </vs-td>
            <vs-td>
              {{ tr.state }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="importData" class="mr-6 mt-5">Actualizar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'

import { db, FieldValue } from '@/firebase/firebaseConfig'

export default {
  name: 'ImportPassengers',
  components: {
    ImportExcel
  },
  data () {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      categories: [],
      colors: [],
      hasErrors: false
    }
  },
  methods: {
    loadDataInTable ({ results, header, meta }) {
      this.header = header
      // Add state
      this.header.push('Estado')
      this.tableData = results

      this.tableData.forEach((d) => {
        this.$set(d, 'state', 'En espera')
        if (!d.ID) {
          this.$set(d, 'state', 'Falta ID')
          this.$set(d, 'ID', 'INCOMPLETO')
        }
        if (!d.NAME) {
          this.$set(d, 'state', 'Falta nombre')
          this.$set(d, 'NAME', 'INCOMPLETO')
        }
        if (!d.SKU) {
          this.$set(d, 'state', 'Falta SKU')
          this.$set(d, 'SKU', 'INCOMPLETO')
        }
      })
      this.sheetName = meta.sheetName
    },
    async importData () {
      try {
        this.initProgress = true
        for await (let p of this.tableData) {
          if (p.state === 'En espera') {
            await this.updateProduct(p)
          }
        }
        if (!this.hasErrors) {
          this.$vs.notify({
            color: 'success',
            title: 'Carga de información',
            text: 'Actualización realizada correctamente.'
          })
        }
        else {
          this.$vs.notify({
            color: 'warning',
            title: 'Carga de información',
            text: 'Actualización completada con errores.'
          })
        }

        this.initProgress = false
      } catch (e) {
        this.initProgress = false
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Save data in firebase
     * @returns {Promise<void>}
     */
    async updateProduct (product) {
      try {
        await db.collection('products').doc(product.ID).update({
          sku: product.SKU,
          name: product.NAME,
          updatedAt: FieldValue.serverTimestamp()
        })
        product.state = 'Ok'
      } catch (e) {
        console.log(e)
        this.hasErrors = true
        product.state = 'Error'
      }
    }
  }
}
</script>
