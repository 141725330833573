import Vue from "vue";
import Router from "vue-router";

import firebase from "firebase/app";
import "firebase/auth";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
      // =============================================================================
      // Main layout routes
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Routes
        // =============================================================================
        {
          path: '/',
          redirect: '',
          component: () => import('./views/Home'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/products/',
          name: 'products',
          component: () => import('./views/products/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/categories/',
          name: 'categories',
          component: () => import('./views/categories/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/brands/',
          name: 'brands',
          component: () => import('./views/brands/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/warehouses/',
          name: 'warehouses',
          component: () => import('./views/warehouses/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/inputs/',
          name: 'inputs',
          component: () => import('./views/inputs/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/outputs/',
          name: 'outputs',
          component: () => import('./views/outputs/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/vehicles/',
          name: 'vehicles',
          component: () => import('./views/vehicles/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/merchants/',
          name: 'merchants',
          component: () => import('./views/merchants/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/transfers/',
          name: 'transfers',
          component: () => import('./views/transfers/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/movements/',
          name: 'movements',
          component: () => import('./views/movements/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/import/products',
          name: 'import',
          component: () => import('./views/ImportProducts'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/import/prices',
          name: 'import-prices',
          component: () => import('./views/products/UpdatePrices'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/import/stocks',
          name: 'import-stocks',
          component: () => import('./views/products/ImportStock'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/import/data',
          name: 'import-data',
          component: () => import('./views/products/BatchImport'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/requests/',
          name: 'requests',
          component: () => import('./views/requests/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'warehouse/orders/',
          name: 'orders',
          component: () => import('./views/orders/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'purchases/providers',
          name: 'providers',
          component: () => import('./views/providers/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'purchases/invoices',
          name: 'invoices',
          component: () => import('./views/invoices/App.vue'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        /**
         * Sales
         */
        {
          path: 'sales/sales',
          name: 'sales',
          component: () => import('./views/sales/App.vue'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'sales/clients',
          name: 'clients',
          component: () => import('./views/clients/App.vue'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'sales/import-product-sales',
          name: 'clients',
          component: () => import('./views/sales/ImportProductSales.vue'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        /**
         * Reports
         */
        {
          path: 'reports/settlements',
          name: 'settlements',
          component: () => import('./views/settlements/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: 'settings/users',
          name: 'users',
          component: () => import('./views/users/App'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('./views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        }

      ]
    },
    // Full Page Layout
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/login/Login.vue'),
          meta: {
            rule: 'editor',
          }
        },
        // Redirect to 404 page, if no match found
        {
          path: '*',
          redirect: '/pages/error-404',
          meta: {
            rule: 'editor'
          }
        }
      ]
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged(() => {

    // get firebase current user
    const firebaseCurrentUser = firebase.auth().currentUser

    // If auth required, check login. If login fails redirect to login page

    if (to.meta.authRequired) {
      if (!(firebaseCurrentUser)) {
        router.push({ path: '/pages/login', query: { to: to.path } })
      }
    }

    return next()

  })

})

export default router
